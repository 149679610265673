import React, { useEffect, useState } from "react";
import HorizontalBar from "../../Assets/Images/HorizontalBar.svg";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import VideoIcon from "../../Assets/Images/VideoIcon.svg";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontFamily: "rajdhani-medium",
  },
}));

function NotebookLevelButtons(props) {
  const {
    data,
    notebookType,
    notebookData,
    isTeachModule,
    selctedLevel,
    setSelectedLevel,
    accordionNotOpen,
    setAccordionNotOpen,
    helpVideo,
    helpVideoLabel,
    onVideoPlayBtnClick,
  } = props;
  const [levelButtonData, setLevelButtonData] = useState([]);
  const [lastLevel, setLastLevel] = useState(0);
  const [isSetLastLevel, setIsSetLastLevel] = useState(false);
  let notebookGameLevelButtonLabel = 1;
  let notebookPuzzleLevelButtonLabel = 1;
  let notebookPythonLevelButtonLabel = 1;

  useEffect(() => {
    if (data) {
      setIsSetLastLevel(false);
      setLastLevel((pre) => 0);
      setSelectedLevel((pre) => -1);
      data.map((lvl, index) => {
        if (lvl.type !== "table" && lvl.type !== "text") {
          setLastLevel((pre) => index);
        }
        return lvl;
      });
      setLevelButtonData(data);
      setIsSetLastLevel(true);
    }
  }, [data, notebookType]);
  const onClickHandler = (index) => {
    setSelectedLevel(index);
    setAccordionNotOpen({
      ...accordionNotOpen,
      [notebookType.toLowerCase()]: {
        ...accordionNotOpen[notebookType.toLowerCase()],
        [index]: false,
      },
    });
    if (accordionNotOpen[notebookType.toLowerCase()][index]) {
      setTimeout(() => {
        document
          .getElementById(`${notebookType.toLowerCase()}-cell-${index}`)
          .scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
      }, 500);
    } else {
      document
        .getElementById(`${notebookType.toLowerCase()}-cell-${index}`)
        .scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
    }
  };
  return (
    <div
      className="row"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div className="col notebook-button-holder" style={{ flex: "1" }}>
        {isSetLastLevel &&
          levelButtonData.length > 0 &&
          levelButtonData.map((lvl, index) => {
            if (lvl.type === "table" || lvl.type === "text") return <></>;
            return (
              <>
                {lastLevel === index ? (
                  <>
                    {notebookType === "Exercise" && lvl?.level?.demoLevel ? (
                      <BootstrapTooltip placement="bottom" title="Demo Level">
                        <button
                          key={`btn-${index}`}
                          className={`${
                            selctedLevel === index ? "active" : ""
                          } ${
                            notebookData?.[
                              `${notebookType.toLowerCase()}_levels`
                            ]?.[index]?.status === "completed"
                              ? "demo-level-completed-btn"
                              : "demo-level-btn"
                          }`}
                          onClick={() => onClickHandler(index)}
                        />
                      </BootstrapTooltip>
                    ) : (
                      <button
                        key={`btn-${index}`}
                        className={selctedLevel === index ? "active" : ""}
                        style={
                          notebookData?.[
                            `${notebookType.toLowerCase()}_levels`
                          ]?.[index]?.status === "completed" ||
                          (isTeachModule && notebookType === "Assessment")
                            ? { backgroundColor: "#25A596" }
                            : selctedLevel === index
                            ? { backgroundColor: "#AECB2A" }
                            : {}
                        }
                        onClick={() => onClickHandler(index)}
                      >
                        {lvl.type === "puzzle"
                          ? `P${notebookPuzzleLevelButtonLabel++}`
                          : lvl.type === "python"
                          ? `Py${notebookPythonLevelButtonLabel++}`
                          : `G${notebookGameLevelButtonLabel++}`}
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    {notebookType === "Exercise" && lvl?.level?.demoLevel ? (
                      <BootstrapTooltip placement="bottom" title="Demo Level">
                        <button
                          key={`btn-${index}`}
                          className={`${
                            selctedLevel === index ? "active" : ""
                          } ${
                            notebookData?.[
                              `${notebookType.toLowerCase()}_levels`
                            ]?.[index]?.status === "completed"
                              ? "demo-level-completed-btn"
                              : "demo-level-btn"
                          }`}
                          onClick={() => onClickHandler(index)}
                        />
                      </BootstrapTooltip>
                    ) : (
                      <button
                        key={`btn-${index}`}
                        className={selctedLevel === index ? "active" : ""}
                        style={
                          notebookData?.[
                            `${notebookType.toLowerCase()}_levels`
                          ]?.[index]?.status === "completed" ||
                          (isTeachModule && notebookType === "Assessment")
                            ? { backgroundColor: "#25A596" }
                            : selctedLevel === index
                            ? { backgroundColor: "#AECB2A" }
                            : {}
                        }
                        onClick={() => onClickHandler(index)}
                      >
                        {lvl.type === "puzzle"
                          ? `P${notebookPuzzleLevelButtonLabel++}`
                          : lvl.type === "python"
                          ? `Py${notebookPythonLevelButtonLabel++}`
                          : `G${notebookGameLevelButtonLabel++}`}
                      </button>
                    )}
                    <img key={`img-${index}`} src={HorizontalBar} />
                  </>
                )}
              </>
            );
          })}
      </div>
      {helpVideo && (
        <div className="notebook-help-video-btn">
          <BootstrapTooltip placement="left" title={helpVideoLabel}>
            <button
              className="watch-help-video ml-auto"
              onClick={onVideoPlayBtnClick}
            >
              <img src={VideoIcon} alt="Video Icon" />
            </button>
          </BootstrapTooltip>
        </div>
      )}
    </div>
  );
}

export default NotebookLevelButtons;
