import React from "react";
import HorizontalBar from "../../../Assets/Images/HorizontalBar.svg";
import Tooltip from "../../Tooltips/Tooltip";

export default function LevelButtons(props) {
  let pythonLevelButtonLabel = 1;
  return (
    <div className="python-button-holder">
      {props.levels.map((lvl, index) => {
        if (index === props.levels.length - 1) {
          return lvl.DemoLevel ? (
            <Tooltip placement="bottom" title="Demo Level">
              <button
                className={`${
                  props.currentLevel === index + 1 ? "active" : ""
                } ${
                  props.statusStorage?.python_levels[index]?.status ===
                  "completed"
                    ? "demo-level-completed-btn"
                    : "demo-level-btn"
                }`}
                key={`btn-${index}`}
                onClick={() => {
                  props.setCurrentLevel(index + 1);
                }}
              />
            </Tooltip>
          ) : (
            <button
              className={props.currentLevel === index + 1 ? "active" : ""}
              style={
                props.statusStorage?.python_levels[index]?.status ===
                "completed"
                  ? { backgroundColor: "#25A596" }
                  : props.statusStorage?.python_levels[index]?.status ===
                    "ongoing"
                  ? { backgroundColor: "#AECB2A" }
                  : {}
              }
              key={`btn-${index}`}
              onClick={() => {
                props.setCurrentLevel(index + 1);
              }}
            >
              {pythonLevelButtonLabel++}
            </button>
          );
        }
        return lvl.DemoLevel ? (
          <>
            <Tooltip placement="bottom" title="Demo Level">
              <button
                className={`${
                  props.currentLevel === index + 1 ? "active" : ""
                } ${
                  props.statusStorage?.python_levels[index]?.status ===
                  "completed"
                    ? "demo-level-completed-btn"
                    : "demo-level-btn"
                }`}
                key={`btn-${index}`}
                onClick={() => {
                  props.setCurrentLevel(index + 1);
                }}
              />
            </Tooltip>
            <img key={`img-${index}`} src={HorizontalBar} />
          </>
        ) : (
          <>
            <button
              className={props.currentLevel === index + 1 ? "active" : ""}
              style={
                props.statusStorage?.python_levels[index]?.status ===
                "completed"
                  ? { backgroundColor: "#25A596" }
                  : props.statusStorage?.python_levels[index]?.status ===
                    "ongoing"
                  ? { backgroundColor: "#AECB2A" }
                  : {}
              }
              key={`btn-${index}`}
              onClick={() => {
                props.setCurrentLevel(index + 1);
              }}
            >
              {pythonLevelButtonLabel++}
            </button>
            <img key={`img-${index}`} src={HorizontalBar} />
          </>
        );
      })}
    </div>
  );
}
