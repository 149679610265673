import React, { useContext, useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useLocation, Link } from "react-router-dom";
import Game from "@smaskar/gameslib";
import { modifyPlaygroundProjectdata } from "../../Utils/LoadGamefromLib";
import Arrow_down_white from "../../Assets/Images/Arrow_down_white.svg";
import Arrow_left_white from "../../Assets/Images/Arrow_left_white.svg";
import Arrow_right_white from "../../Assets/Images/Arrow_right_white.svg";
import Arrow_up_white from "../../Assets/Images/Arrow_up_white.svg";
import "../../Styles/Playground.css";
import ArrowLeft from "../../Assets/Images/ArrowLeft.svg";
import Textarea from "@mui/joy/Textarea";
import { authUserContext } from "../../Components/Contexts/AuthUser";

function arrow_keys_handler(e) {
  if ([32, 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
    e.preventDefault();
  }
}

export default function PlaygroundPage() {
  const {user} = useContext(authUserContext);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [state, setState] = useState(location?.state);
  const [projectData, setProjectData] = useState(
    state && state?.type === "view" ? state?.projectDetails : {}
  );
  const [viewType, setViewType] = useState(
    state?.type ? state?.type : "create"
  );
  const [projectDescription, setProjectDescription] = useState(
    state?.projectDetails?.description ? state?.projectDetails?.description : ""
  );
  const [grade, setGrade] = useState(state?.grade ? state?.grade : null);

  document.title =
    state?.type === "view"
      ? "CSSoch | Project View"
      : state?.type === "create"
      ? "CSSoch | Project Create"
      : "CSSoch | Project Edit";
  const [section, setSection] = useState(state?.section ? state?.section : "");
  const [playgroundGameData, setPlaygroundGameData] = useState();
  const [playgroundGameInfo, setPlaygroundGameInfo] = useState();
  const [playgroundGame, setPlaygroundGame] = useState();
  const [gamesetName, setGamesetName] = useState(state?.projectDetails?.title);
  const [submitButtonText, setSubmitButtonText] = useState("Run Code");
  const [configFile, setConfigFile] = useState();
  const [playgroundSetupCompleted, setPlaygroundGameSetupCompleted] =
    useState(false);
  const [publishedProject, setPublishedProject] = useState(
    state?.projectDetails?.published ? true : false
  );

  useEffect(async () => {
    const strapi_game_set_id = state?.projectDetails?.strapi_game_set_id;
    async function fetchPlaygroundDataFromStrapi() {
      const strapiDataResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/cms/playground-game-set/${strapi_game_set_id}`
      );
      const gameSetData = await strapiDataResponse.json();
      fetch(gameSetData["Config File"])
        .then((res) => res.json())
        .then((json) => {
          setConfigFile(json);
        });
      setPlaygroundGameData(gameSetData);
      const gameInfo = await Promise.resolve(
        modifyPlaygroundProjectdata(gameSetData)
      );
      setPlaygroundGameInfo(gameInfo);
      setPlaygroundGame(new Game(gameInfo?.gameType));
    }
    async function fetchStudentProjectData() {
      const projectDataResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/get-playground-game-data/${state?.projectDetails?.project_id}`
      );
      const playgroundProjectData = await projectDataResponse.json();
      setProjectData(playgroundProjectData);
    }
    
    await fetchPlaygroundDataFromStrapi();
    if(state?.type !== "view") await fetchStudentProjectData();
    setIsLoading(false);
  }, []);



  useEffect(() => {
    if (!isLoading) {
      if (playgroundGame) {
        playgroundGame.setup(playgroundGameInfo?.options, false);
        playgroundGame.loadBlocklyImgs(playgroundGameInfo?.blocklyAsset);
        playgroundGame.loadAssets(playgroundGameInfo?.assets);
        setPlaygroundGameSetupCompleted(true);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (playgroundSetupCompleted && configFile) {
      playgroundGame.loadLevel(configFile);
      if (projectData?.code) {
        playgroundGame?.loadCode(projectData?.code?.toString());
      }
    }
  }, [playgroundSetupCompleted, configFile]);

  useEffect(() => {
    if (viewType === "view") return;
    if (submitButtonText === "Reset") {
      projectData.code = playgroundGame.fetchCode();
      if (projectData.published) {
        // while publishing the project we are updating data of the project in community projects and in playground projects
        PublishProject();
      } else {
        uploadProjectData();
      }
    }
  }, [submitButtonText]);
  
  useEffect(() => {
    return () => {
      if(playgroundGame) {
        playgroundGame?.clearGameWindow();
        window.removeEventListener("keydown", arrow_keys_handler, false);
      }
    };
  }, [playgroundGame]);

  function runCode() {
    playgroundGame.setFailure((errorCode) => {
      for (const item of playgroundGameInfo?.errorMessages) {
        if (item.Code === errorCode) {
          console.log("Error:", item.Message);
          break;
        }
      }
      window.removeEventListener("keydown", arrow_keys_handler, false);
    });
    playgroundGame.setSuccess(() => {
      const x = playgroundGameInfo?.successMessages.length;
      if (x > 0) {
        const msg =
          playgroundGameInfo?.successMessages[Math.floor(Math.random() * x)];
        if (msg === "") {
          console.log("Success: ", playgroundGameInfo?.successMessages[0]);
        } else {
          console.log("Success: ", msg);
        }
      } else {
        console.log("Success: ", " ");
      }
      window.removeEventListener("keydown", arrow_keys_handler, false);
    });
    playgroundGame.runCode();
  }

  function handleRunRestartButtonClick() {
    if (submitButtonText === "Run Code") {
      window.addEventListener("keydown", arrow_keys_handler, false);
      runCode();
      setSubmitButtonText("Reset");
    } else {
      window.removeEventListener("keydown", arrow_keys_handler, false);
      playgroundGame.restart();
      setSubmitButtonText("Run Code");
    }
  }

  const uploadProjectData = () => {
    let payload = {
      project_data: {
        project_id: projectData?.project_id,
        code: projectData.code,
      },
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/upload-playground-project`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setProjectData({ ...projectData, ...data });
      });
  };

  const PublishProject = () => {
    projectData.status = "Active";
    projectData.published = true;
    const payload = {
      project_data: projectData,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/publish-playground-project`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setProjectData({ ...projectData, ...data.project_data });
        setPublishedProject(true);
      });
  };

  const UnpublishProject = () => {
    const payload = {
      project_id: projectData.project_id,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/unpublish-playground-project`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setProjectData(data);
        setPublishedProject(false);
      });
  };

  const SaveProjectDescription = () => {
    projectData.description = projectDescription;
    if (projectData.published) {
      PublishProject();
    } else {
      const payload = {
        project_data: {
          project_id: projectData.project_id,
          description: projectDescription,
        },
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      };
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/upload-playground-project`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          setProjectData({ ...projectData, ...data });
        });
    }
  };

  return (
    <Container className='playground-container'>
      <div className='playground-content'>
        <div className='playground-content-wrapper'>
          <div className='actions-wrapper'>
            <Link
              to={
                user.role.includes("teacher") ? `/community/${grade}` : "/home"
              }
              className='return-community-button'
              state={{ tab: "community" }}
              onClick={() => {
                playgroundGame.clearGameWindow();
                window.removeEventListener("keydown", arrow_keys_handler, false);
              }}
            >
              <span className='playground-return-home d-flex align-items-center'>
                <img src={ArrowLeft} alt='' />
                <span className='back-text'>Back</span>
              </span>
            </Link>{" "}
            <h1 className='game-set-name'>{gamesetName}</h1>
            {viewType !== "view" ? (
              <div>
                {" "}
                <button
                  id='game-publish-button'
                  disabled={!projectData?.project_id}
                  onClick={publishedProject ? UnpublishProject : PublishProject}
                >
                  {projectData?.published ? "Unshare" : "Share"}
                </button>
              </div>
            ) : (
              <p className='student-name'>
                <span
                  style={{ fontWeight: 400, fontFamily: "rajdhani-medium" }}
                >
                  Created By:{" "}
                </span>
                {projectData?.student_name ? projectData?.student_name : ""},{" "}
                {projectData?.section ? projectData?.section : ""}
              </p>
            )}
          </div>
          <div>
            <div id='game-view' className='d-hidden'>
              <div className='game-content-wrapper'>
                <div id='canvas' />{" "}
                <div id='blocklyArea'>
                  <div id='blockly' />
                </div>
              </div>
              <div className='button-container d-flex mt-1'>
                <div className='action-buttons' style={{ marginLeft: 0 }}>
                  <button
                    id='run-restart-button'
                    onClick={handleRunRestartButtonClick}
                    style={
                      submitButtonText === "Reset"
                        ? { backgroundColor: "#FFB017", lineHeight: "2.2vw" }
                        : { lineHeight: "2.2vw" }
                    }
                  >
                    {submitButtonText}
                  </button>
                  {playgroundGameInfo?.arrowButtons && (
                    <>
                      <button
                        id='game-up'
                        onClick={() => {
                          playgroundGame.keyClicked("up");
                        }}
                      >
                        <img src={Arrow_up_white} />
                      </button>
                      <button
                        id='game-down'
                        onClick={() => {
                          playgroundGame.keyClicked("down");
                        }}
                      >
                        <img src={Arrow_down_white} />
                      </button>
                      <button
                        id='game-left'
                        onClick={() => {
                          playgroundGame.keyClicked("left");
                        }}
                      >
                        <img src={Arrow_left_white} />
                      </button>
                      <button
                        id='game-right'
                        onClick={() => {
                          playgroundGame.keyClicked("right");
                        }}
                      >
                        <img src={Arrow_right_white} />
                      </button>
                    </>
                  )}
                </div>
              </div>
              <xml id='toolbox' style={{ display: "none" }} />
            </div>
          </div>
          <div className='project-description-container'>
            <div className='project-description-header'>
              <p className='project-description-title'>Project Description </p>
            </div>
            <Textarea
              color='neutral'
              minRows={3}
              size='lg'
              variant='outlined'
              value={
                projectDescription
                  ? projectDescription
                  : viewType !== "view"
                  ? projectDescription
                  : "No Description"
              }
              onChange={(e) => {
                if (e.target.value.length < 300)
                  setProjectDescription(e.target.value);
              }}
              disabled={viewType === "view"}
            />
            {viewType !== "view" && (
              <button
                id='description-save-button'
                onClick={SaveProjectDescription}
              >
                Save Description
              </button>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}
