import { CircularProgress, Typography, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ModuleProgressBar from "./Progress/ModuleProgressBar";
import ExerciseProgress from "./Progress/ExerciseProgress";
import Feedback from "./Progress/Feedback";
import Rewards from "./Progress/Rewards";
import AssesmentProgress from "./Progress/AssesmentProgress";
import ModuleCompletion from "./Progress/ModuleCompletion";
import SkillProfile from "./Progress/SkillProfile";
import RecentRewards from "./Progress/RecentRewards";
import "../../Styles/Progress.css";

export default function Progress(props) {
  document.title = "CSSoch | Progress";
  const { moduleStatusData, section } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isAssesmentCompleted, setIsAssesmentCompleted] = useState(true);
  const [overallProgress, setOverallProgress] = useState([]);
  const [progressData, setProgressData] = useState();
  const [feedbackData, setFeedbackData] = useState();
  const [currentModuleNum, setCurrentModuleNum] = useState(-1);
  const [feedbackStars, setFeedbackStar] = useState([]);
  const [assesmentGameReportData, setAssesmentGameReportData] = useState([]);
  const [assesmentPuzzleReportData, setAssesmentPuzzleReportData] = useState(
    []
  );
  const [assesmentPythonReportData, setAssesmentPythonReportData] = useState(
    []
  );
  const [exerciseData, setExerciseData] = useState([]);
  const [assesmentScore, setAssesmentScore] = useState([0, 0]);
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const [noOfExerciseComponentsInModule, setNoOfExerciseComponentsInModule] =
    useState(2);
  const [moduleData, setModuleData] = useState();

  useEffect(() => {
    if (moduleStatusData && section) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/progress`)
        .then((res) => {
          if (res.redirected === true) {
            document.location = "/landing";
          }
          return res.json();
        })
        .then((data) => {
          setProgressData(data.module_progress_data);
          setFeedbackData(data.feedback);
          setOverallProgress(data.overall_progress);
        });
    }
  }, [moduleStatusData, section]);

  useEffect(() => {
    if (moduleStatusData) {
      const moduleStusDataCopy = JSON.parse(JSON.stringify(moduleStatusData));
      setModuleData(moduleStusDataCopy);
    }
  }, [moduleStatusData]);

  useEffect(() => {
    if (feedbackData && progressData && currentModuleNum >= 0) {
      if (moduleData?.statuses[currentModuleNum] !== "locked") {
        const stars = [];
        if (feedbackData[currentModuleNum] > 0) {
          setIsFeedbackSubmitted(true);
        } else {
          setIsFeedbackSubmitted(false);
        }
        for (let i = 1; i <= 5; i++) {
          if (i <= feedbackData[currentModuleNum]) {
            stars.push(1);
          } else {
            stars.push(0);
          }
        }
        setFeedbackStar(stars);
        setExerciseData(progressData[currentModuleNum]["exercise"]);
        const noOfComponents = Object.keys(
          progressData[currentModuleNum]["exercise"]
        ).length;
        setNoOfExerciseComponentsInModule(noOfComponents);
        if (progressData[currentModuleNum]["assessment"] !== false) {
          setIsAssesmentCompleted(true);
        } else {
          setIsAssesmentCompleted(false);
          return;
        }
        const assesmentGameScore = [];
        const assesmentPuzzleScore = [];
        const assesmentPythonScore = [];
        const assesmentTotalScore = [0, 0];
        let gameIndex = 1;
        let puzzleIndex = 1;
        let pytonIndex = 1;
        progressData[currentModuleNum]["assessment"].map((item, index) => {
          if (item.type === "game" || item.type === "python") {
            let score = 5;
            let attr = "good";
            let result = "Correct";
            if (item.giveup === 1) {
              attr = "bad";
              result = "Gave Up";
              score = 0;
            }
            if (item?.type === "python") {
              assesmentPythonScore.push({
                num: pytonIndex,
                attempts: item.attempts,
                attr,
                result,
                score,
              });
              assesmentTotalScore[0] += score;
              assesmentTotalScore[1] += 5;
              pytonIndex++;
            } else {
              assesmentGameScore.push({
                num: gameIndex,
                attempts: item.attempts,
                attr,
                result,
                score,
              });
              assesmentTotalScore[0] += score;
              assesmentTotalScore[1] += 5;
              gameIndex++;
            }
          } else {
            let attr = "good";
            let result = "Correct";
            let score = 1;
            if (item.giveup === 1) {
              attr = "bad";
              result = "Gave Up";
              score = 0;
            } else if (item.result === 0) {
              attr = "bad";
              result = "Incorrect";
              score = 0;
            }
            assesmentPuzzleScore.push({
              num: puzzleIndex,
              attr,
              result,
              score,
            });
            assesmentTotalScore[0] += score;
            assesmentTotalScore[1] += 1;
            puzzleIndex++;
          }
        });
        setAssesmentScore(assesmentTotalScore);
        setAssesmentGameReportData(assesmentGameScore);
        setAssesmentPuzzleReportData(assesmentPuzzleScore);
        setAssesmentPythonReportData(assesmentPythonScore);
      }
      setIsLoading(false);
    } else if (progressData && feedbackData) {
      setIsLoading(false);
    }
  }, [currentModuleNum, progressData, feedbackData]);

  return (
    <div className="progress-content">
      {!isLoading ? (
        <>
          <ModuleProgressBar
            currentModuleIndex={currentModuleNum}
            setCurrentModuleIndex={setCurrentModuleNum}
            moduleStatusData={moduleData}
          />
          {moduleData && moduleData?.statuses[currentModuleNum] !== "locked" ? (
            <>
              {currentModuleNum >= 0 ? (
                <>
                  <div className="progress-module-header-container">
                    <p className="progress-module-header">
                      Module
                      {` ${currentModuleNum + 1}`}:
                      {` ${moduleData?.names[currentModuleNum]}`}
                    </p>
                    <p
                      className="back-to-overall"
                      onClick={() => setCurrentModuleNum(-1)}
                    >
                      <u> Back to Overall Progress</u>
                    </p>
                  </div>
                  <Grid container className="progress-content-container">
                    <Grid
                      container
                      item
                      xs={12}
                      className="progress-exercise-assesment"
                    >
                      <Grid
                        item
                        xs={noOfExerciseComponentsInModule > 2 ? 6.75 : 5.75}
                        className="progress-exercise-container"
                      >
                        <Grid container item className="progress-exercise">
                          <ExerciseProgress
                            exerciseData={exerciseData}
                            noOfExerciseComponentsInModule={
                              noOfExerciseComponentsInModule
                            }
                          />
                        </Grid>
                        <Grid className="demolevel-label">
                          <p>DL: Demo Level</p>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={noOfExerciseComponentsInModule > 2 ? 4.75 : 5.75}
                        className="progress-assesment"
                      >
                        <AssesmentProgress
                          assesmentGameReportData={assesmentGameReportData}
                          isAssesmentCompleted={isAssesmentCompleted}
                          assesmentPuzzleReportData={assesmentPuzzleReportData}
                          assesmentPythonReportData={assesmentPythonReportData}
                          assesmentScore={assesmentScore}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      className="progress-feedback-rewards"
                    >
                      <Grid item xs={5.75} className="progress-feedback">
                        <Feedback
                          isFeedbackSubmitted={isFeedbackSubmitted}
                          feedbackStars={feedbackStars}
                        />
                      </Grid>
                      <Grid item xs={5.75} className="progress-rewards">
                        <Rewards />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                overallProgress && (
                  <>
                    <p className="overall-progress-module-header">
                      Overall Progress
                    </p>
                    <Grid
                      container
                      className="overall-progress-content-container"
                    >
                      <Grid
                        container
                        item
                        xs={12}
                        className="module-completion-container"
                      >
                        <Grid
                          container
                          item
                          xs={11.75}
                          className="module-completion-progress"
                        >
                          <ModuleCompletion
                            module_completion_data={
                              overallProgress.module_completion_data
                            }
                            isPython={overallProgress.isPython}
                            moduleData={moduleData}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        className="skill-profile-recent-rewards"
                      >
                        <Grid item xs={7.5} className="skills-profile">
                          <SkillProfile
                            skills_acuired={overallProgress.skills_acuired}
                          />
                        </Grid>
                        <Grid item xs={4} className="recent-rewards">
                          <RecentRewards />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )
              )}
            </>
          ) : (
            <div className="progress-locked-box">
              <p>Module is not assigned yet</p>
            </div>
          )}
        </>
      ) : (
        <div className="progress-loading-box">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
