import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../../Styles/Global.css";
import "../../Styles/TeacherDashboard.css";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";
import { setStudentPerformanceData } from "../../features/TeacherUserData/StudentPerformanceData";

export default function StudentProgressReport(props) {
  const {
    activeStudentId,
    studentPerformanceData,
    studentName,
    selectedModuleId,
    moduleStatus,
    goToViewStudentModulePage,
    moduleType,
    perfromanceData,
    selectedModule,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [selectedFeedbackStars, setSelectedFeedbackStars] = useState(
    studentPerformanceData?.feedback
  );
  const starsArray = [1, 2, 3, 4, 5];
  const dispatch = useDispatch();

  useEffect(() => {
    setShowPassword(false);
    setSelectedFeedbackStars(studentPerformanceData?.feedback);
  }, [studentPerformanceData]);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const update_student_feedback = (stars) => {
    if (stars !== selectedFeedbackStars) {
      setSelectedFeedbackStars(stars);
    } else {
      stars = -1;
      setSelectedFeedbackStars(-1);
    }
    const payload = {
      id: selectedModuleId,
      user_id: activeStudentId,
      stars: stars,
      type: moduleType,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/feedback-upload`,
      requestOptions
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        const contentType = res.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          return res.json();
        } else {
          return {};
        }
      })
      .then((data) => {
        dispatch(
          setStudentPerformanceData({
            ...perfromanceData,
            [selectedModule]: {
              ...perfromanceData[selectedModule],
              [activeStudentId]: { ...studentPerformanceData, feedback: stars },
            },
          })
        );
        return;
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  return (
    <div style={{ overflow: "auto", height: "inherit" }}>
      <div className="student-progress-box">
        {moduleStatus === "assigned" ? (
          <>
            {activeStudentId ? (
              <div className="student-report">
                <div className="report-header">
                  <h2>{`${studentName?.split(" ")[0]}'s`} Report</h2>
                  <h4 className="student-username-password">
                    <span className="username-box">
                      <span>Username:</span>{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {studentPerformanceData?.username}
                      </span>
                    </span>
                    <span
                      className="password-box"
                      style={{
                        margin: "0",
                        marginLeft: "0.5vw",
                        marginRight: "0.25vw",
                      }}
                    >
                      <span>Password:</span>{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {showPassword
                          ? studentPerformanceData?.password
                          : "****"}
                      </span>
                    </span>
                    <span className="visibility-icon">
                      <IconButton
                        aria-label="togglepasswordvisibility"
                        onClick={handleClickShowPassword}
                        style={{ width: "10%" }}
                      >
                        {showPassword ? (
                          <VisibilityIcon
                            style={{ width: "1.5vw", color: "#212529" }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            style={{ width: "1.5vw", color: "#212529" }}
                          />
                        )}
                      </IconButton>
                    </span>
                  </h4>
                </div>
                <div id="feedback-seework-container">
                  <div id="feedback">
                    <h3>Feedback</h3>
                    <div className="teacher-feedback-box">
                      {starsArray?.map((item) => (
                        <svg
                          className="star"
                          xmlns="http://www.w3.org/2000/svg"
                          width="91.02"
                          height="50"
                          viewBox="0 0 91.02 90"
                          key={item}
                          onClick={() => {
                            update_student_feedback(item);
                          }}
                        >
                          <g transform="translate(-4.491 -6.621)">
                            <path
                              d="M6.1,42.294,26.36,62.043l-4.785,27.88c-.444,2.575,1.076,3.678,3.386,2.461L50,79.218,75.04,92.384c2.314,1.217,3.832.114,3.387-2.461l-4.781-27.88L93.9,42.294c1.87-1.823,1.29-3.6-1.294-3.979L64.617,34.247,52.094,8.878c-1.157-2.343-3.029-2.343-4.188,0L35.388,34.246l-28,4.068C4.8,38.688,4.229,40.471,6.1,42.294Z"
                              className={`star-coloring ${
                                item <= selectedFeedbackStars ? "active" : ""
                              }`}
                              fill="#ffffff"
                              stroke="#ffcca0"
                              strokeWidth="1"
                            ></path>
                          </g>
                        </svg>
                      ))}
                    </div>
                  </div>
                  <button
                    className="see-work"
                    onClick={() => goToViewStudentModulePage("View Work")}
                  >
                    <span>View {`${studentName?.split(" ")[0]}'s`} Work</span>
                    <img
                      className="see-work-icon"
                      src="/svg/SeeStudentWork.svg"
                      alt=""
                    />
                  </button>
                </div>
                <div id="exercises">
                  <h3>Exercises</h3>
                  <div className="table-holder row">
                    {studentPerformanceData?.game ? (
                      <div className="report-table-cell">
                        <h4>Games</h4>
                        <table className="table table-bordered exercises-games-table report-table">
                          <thead>
                            <tr>
                              <th scope="col">Question</th>
                              <th scope="col">Attempts</th>
                              <th scope="col">Completion</th>
                            </tr>
                          </thead>
                          <tbody>
                            {studentPerformanceData?.game?.map(
                              (item, index) => (
                                <tr key={index}>
                                  <td>{item.index}</td>
                                  <td>{item.attempts}</td>
                                  <td>
                                    {item.completion.charAt(0).toUpperCase() +
                                      item.completion.slice(1).toLowerCase()}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <></>
                    )}
                    {studentPerformanceData?.puzzle ? (
                      <div className="report-table-cell">
                        <h4>Puzzles</h4>
                        <table className="table table-bordered exercises-puzzles-table report-table">
                          <thead>
                            <tr>
                              <th scope="col">Question</th>
                              <th scope="col">Result</th>
                              <th scope="col">Completion</th>
                            </tr>
                          </thead>
                          <tbody>
                            {studentPerformanceData?.puzzle?.map(
                              (item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    {item.result ? "Correct" : "Incorrect"}
                                  </td>
                                  <td>
                                    {item.completion.charAt(0).toUpperCase() +
                                      item.completion.slice(1).toLowerCase()}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <></>
                    )}
                    {studentPerformanceData?.python ? (
                      <div className="report-table-cell python-report-table-cell">
                        <h4>Python</h4>
                        <table className="table table-bordered exercises-python-table report-table">
                          <thead>
                            <tr>
                              <th scope="col">Question</th>
                              <th scope="col">Attempts</th>
                              <th scope="col">Completion</th>
                            </tr>
                          </thead>
                          <tbody>
                            {studentPerformanceData?.python?.map(
                              (item, index) => (
                                <tr key={index}>
                                  <td>{item?.index}</td>
                                  <td>{item.attempts}</td>
                                  <td>
                                    {item.completion.charAt(0).toUpperCase() +
                                      item.completion.slice(1).toLowerCase()}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="demolevel-label">
                    <p>DL: Demo Level</p>
                  </div>
                </div>
                <div id="assesment">
                  <h3>Assessment</h3>
                  {studentPerformanceData?.assesment_state === "completed" ? (
                    <div className="table-holder row">
                      {studentPerformanceData?.gameScore ? (
                        <div
                          className="report-table-cell game-table-cell"
                          style={
                            !studentPerformanceData?.puzzleScore &&
                            studentPerformanceData?.pythonScore
                              ? { width: "51%" }
                              : {}
                          }
                        >
                          <h4>Games</h4>
                          <table className="table table-bordered assesment-games-table report-table">
                            <thead>
                              <tr>
                                <th scope="col">Question</th>
                                <th scope="col">Attempts</th>
                                <th scope="col">Result</th>
                                <th scope="col">Score</th>
                              </tr>
                            </thead>
                            <tbody>
                              {studentPerformanceData?.assesment?.map(
                                (item, index) => {
                                  if (item.type === "game")
                                    return (
                                      <tr key={index}>
                                        <td>{item.question}</td>
                                        <td>{item.attempts}</td>
                                        <td>
                                          {item.result.charAt(0).toUpperCase() +
                                            item.result.slice(1).toLowerCase()}
                                        </td>
                                        <td>{item.score}/5</td>
                                      </tr>
                                    );
                                }
                              )}
                              <tr>
                                <td colSpan="3">Total</td>
                                <td>{studentPerformanceData?.gameScore}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <></>
                      )}
                      {studentPerformanceData?.puzzleScore ? (
                        <div className="report-table-cell puzzle-table-cell">
                          <h4>Puzzles</h4>
                          <table className="table table-bordered assesment-puzzles-table report-table">
                            <thead>
                              <tr>
                                <th scope="col">Question</th>
                                <th scope="col">Result</th>
                                <th scope="col">Score</th>
                              </tr>
                            </thead>
                            <tbody>
                              {studentPerformanceData?.assesment?.map(
                                (item, index) => {
                                  if (item.type === "puzzle") {
                                    return (
                                      <tr key={index}>
                                        <td>{item.question}</td>
                                        <td>
                                          {item.result.charAt(0).toUpperCase() +
                                            item.result.slice(1).toLowerCase()}
                                        </td>
                                        <td>{item.score}/1</td>
                                      </tr>
                                    );
                                  }
                                }
                              )}
                              <tr>
                                <td colSpan="2">Total</td>
                                <td>{studentPerformanceData?.puzzleScore}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <></>
                      )}
                      {studentPerformanceData?.pythonScore ? (
                        <div
                          className="report-table-cell python-table-cell"
                          style={
                            !studentPerformanceData?.puzzleScore &&
                            studentPerformanceData?.gameScore
                              ? { width: "51%" }
                              : {}
                          }
                        >
                          <h4>Python</h4>
                          <table className="table table-bordered assesment-games-table report-table">
                            <thead>
                              <tr>
                                <th scope="col">Question</th>
                                <th scope="col">Attempts</th>
                                <th scope="col">Result</th>
                                <th scope="col">Score</th>
                              </tr>
                            </thead>
                            <tbody>
                              {studentPerformanceData?.assesment?.map(
                                (item, index) => {
                                  if (item.type === "python")
                                    return (
                                      <tr key={index}>
                                        <td>{item.question}</td>
                                        <td>{item.attempts}</td>
                                        <td>
                                          {item.result.charAt(0).toUpperCase() +
                                            item.result.slice(1).toLowerCase()}
                                        </td>
                                        <td>{item.score}/5</td>
                                      </tr>
                                    );
                                }
                              )}
                              <tr>
                                <td colSpan="3">Total</td>
                                <td>{studentPerformanceData?.pythonScore}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <p className="assesment-not-completed">
                      {studentName} hasn't completed the assesment yet.
                    </p>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <div className="not-assigned-box">
            <h3>Module Not Assigned</h3>
            <p>
              This module hasn’t been assigned yet. Assign it to see the work of
              all the students and give feedback.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
