import { preBuiltCode } from "../../../../Utils/Constants/GraphicPyodideConstants.js";
import {
  inputDisabledMessage,
  interruptExecutionMessage,
  timeoutMessage,
  defineExceptions,
} from "../../../../Utils/Constants/PythonConstants.js";
import TimerHandler from "./timerHandler.js";
import { formatError } from "../PythonFormatError.js";

function GraphicPyodide(consoleObj) {
  let pyodide;
  let graphicType = "default";
  let userCode = "";
  let consoleElement = consoleObj;
  let currentProgramConsoleOutput = "";
  let interruptBuffer = null;
  if (window.crossOriginIsolated) {
    interruptBuffer = new Uint8Array(new window.SharedArrayBuffer(1));
  } else {
    interruptBuffer = new Uint8Array(new ArrayBuffer(1));
  }
  let checkForInterruptInterval = null;
  let onErrorCallback = () => {};
  let timerHandler;
  let evaluatingConsoleCodeFirstTimeForThisLevel = true;

  const graphicMapper = {
    clicker: [
      preBuiltCode.graphicClickerCode,
      "change_colour(colour)",
      "Clicker",
    ],
    bouncer: [preBuiltCode.graphicBouncerCode, "at_edge(x)", "Bouncer"],
    growingsun: [
      preBuiltCode.graphicGrowingsunCode,
      "get_new_size(size)",
      "GrowingSun",
    ],
    speedball: [
      preBuiltCode.graphicSpeedballCode,
      "get_new_speed(speed)",
      "SpeedBall",
    ],
    rockboat: [
      preBuiltCode.graphicRockboatCode,
      "change_direction(angle)",
      "RockBoat",
    ],
  };

  this.setup = async function () {
    timerHandler = new TimerHandler();
    timerHandler.initialize(interruptBuffer);
    const config = {
      stdout: (output) => {
        currentProgramConsoleOutput += output + "\n";
        outputToConsole(output, false);
      },
    };
    pyodide = await window.loadPyodide(config);
    await pyodide;
    pyodide.setInterruptBuffer(interruptBuffer);
    runInitialCode();
    consoleElement.enable();
  };

  function runInitialCode() {
    window.log_error_to_console = function (error) {
      handleError(error);
    };
    window.remove_p5_instance = function (p5Instance) {
      p5Instance?.remove();
    };
    let code = buildCode(
      preBuiltCode.importCode,
      defineExceptions,
      preBuiltCode.placeholderCode,
      preBuiltCode.wrapperCode,
      preBuiltCode.defineNamespaceCode
    );
    pyodide.runPython(code);
  }

  this.setOnErrorCallback = function (callback) {
    onErrorCallback = callback;
  };

  this.evaluateConsoleCode = function (codeLine) {
    try {
      if (evaluatingConsoleCodeFirstTimeForThisLevel) this.clearNamespace();
      evaluatingConsoleCodeFirstTimeForThisLevel = false;
      timerHandler.initiateInitializationTimer();
      let programOutput = pyodide.runPython(codeLine);
      timerHandler.cancelInitializationTimer();
      if (programOutput !== undefined) outputToConsole(programOutput, false);
    } catch (error) {
      timerHandler.cancelInitializationTimer();
      let formattedError = formatError(
        String(error),
        true,
        userCode,
        interruptExecutionMessage,
        inputDisabledMessage,
        timeoutMessage
      );
      outputToConsole(formattedError, true);
    }
  };

  this.runCode = function (code, type = null, levelNum = null) {
    userCode = code;
    currentProgramConsoleOutput = "";
    interruptBuffer[0] = 0;
    resetWindow();
    let initializeCode = buildCode(
      preBuiltCode.clearNamespaceCode,
      preBuiltCode.placeholderCode
    );
    let levelCode = "";
    if (graphicType !== "default") {
      levelCode = graphicMapper[graphicType][0];
      outputToConsole(
        `<< ${graphicMapper[graphicType][2]} Game Loaded >>`,
        false
      );
    }
    let sketchHolderName = "sketch-holder";
    if (type && levelNum) {
      sketchHolderName = `${type}-sketch-holder-${levelNum}`;
    } else if (type) {
      sketchHolderName = `${type}-sketch-holder`;
    }
    const sketchHolder = `
global sketch_holder
sketch_holder = "${sketchHolderName}";
    `;
    let mainCode = buildCode(
      sketchHolder,
      userCode,
      levelCode,
      preBuiltCode.wrapperCode,
      preBuiltCode.startCode
    );
    try {
      pyodide.runPython(initializeCode);
      timerHandler.initiateInitializationTimer();
      setCheckForInterruptInterval();
      pyodide.runPython(mainCode);
      timerHandler.cancelInitializationTimer();
    } catch (error) {
      timerHandler.cancelInitializationTimer();
      handleError(error);
    }
  };

  function setCheckForInterruptInterval() {
    if (checkForInterruptInterval != null) return;
    checkForInterruptInterval = setInterval(() => {
      if (interruptBuffer[0] === 2) {
        interruptBuffer[0] = 0;
        pyodide.runPython(`
                try:
                    raise Exception('${interruptExecutionMessage}')
                except:
                    cancel_worker_timer()
                    traceback_str = traceback.format_exc()
                    log_error_to_console(traceback_str)
                `);
      }
    }, 500);
  }

  function clearCheckForInterruptInterval() {
    clearInterval(checkForInterruptInterval);
    checkForInterruptInterval = null;
  }

  function handleError(err) {
    resetWindow();
    clearCheckForInterruptInterval();
    let formattedError = formatError(
      String(err),
      false,
      userCode,
      interruptExecutionMessage,
      inputDisabledMessage,
      timeoutMessage
    );
    let errList = formattedError.split("\n");
    onErrorCallback(errList[errList.length - 1]);
    outputToConsole(formattedError, true);
  }

  function outputToConsole(output, errorOccurred) {
    consoleElement.addMessage(output + "\n", errorOccurred);
  }

  function resetWindow() {
    if (window.instance) {
      window.instance.remove();
    }
  }

  function buildCode() {
    let result = [];
    for (let i = 0; i < arguments.length; i++) {
      result.push(arguments[i]);
    }
    return result.join("\n");
  }

  this.stopExecution = function () {
    interruptBuffer[0] = 2;
  };

  this.runTests = function (codeToCheck, jsonFile) {
    consoleElement.disable();
    timerHandler.initiateInitializationTimer();
    let testResults = getTestResults(codeToCheck, jsonFile);
    timerHandler.cancelInitializationTimer();
    consoleElement.enable();
    if (testResults[0].has("ErrorEncountered")) {
      outputToConsole("We got the following error while testing:", false);
      outputToConsole(testResults[0].get("ErrorEncountered"), true);
    }
    return testResults;
  };

  function getTestResults(codeToCheck, jsonFile) {
    let consoleOutput = currentProgramConsoleOutput;
    pyodide.globals.set("code_to_check", codeToCheck);
    pyodide.globals.set("console_output", consoleOutput);
    pyodide.globals.set("json_file", jsonFile);
    return pyodide.runPython(preBuiltCode.testingCode).toJs();
  }

  this.setGraphicType = function (type) {
    if (type in graphicMapper) {
      graphicType = type;
      evaluatingConsoleCodeFirstTimeForThisLevel = true;
    } else {
      graphicType = "default";
      evaluatingConsoleCodeFirstTimeForThisLevel = true;
    }
  };

  this.setDefaultGraphicType = function () {
    graphicType = "default";
  };

  this.getFunctionSignatureForGraphic = function () {
    return graphicMapper[graphicType][1];
  };

  this.clearNamespace = function () {
    pyodide?.runPython(preBuiltCode.clearNamespaceCode);
  };
}

export default GraphicPyodide;
