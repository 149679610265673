import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CardActionArea,
  Container,
  Typography,
  CardMedia,
  CardContent,
  Card,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import CreateProjectModal from "./CreateProjectModal";
import ExpandableSectionHandler from "./ExpandableSectionHandler";
import ExpandableProjectGroup from "./ExpandableProjectGroup";
import ProjectCardsContainer from "./ProjectCardsContainer";
import CommunityProjectCard from "./CommunityProjectCard";
import { setExpandedSections } from "../../features/CommunityData/CommunityState";

export default function PlaygroundProjects(props) {
  const { type, projects, playgroundProjectIdList, helpInstructionIds } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const expandedSections = useSelector(
    (state) => state.communityState.expandedSections
  );

  useEffect(() => {
    if (type === "Community Projects") {
      const newExpandedSections = {};
      for (let section in projects) {
        if (projects[section].length) {
          // Preserve existing state or default to false
          newExpandedSections[section] = expandedSections[section] ?? false;
        }
      }
      // Only initialize if no existing state
      if (Object.keys(expandedSections).length === 0) {
        dispatch(setExpandedSections(newExpandedSections));
      }
    }
  }, [type]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const gotoPlayground = (project, type) => {
    const state = {
      projectDetails: project,
      type: type,
      section: props.section,
      helpInstructionIds: helpInstructionIds,
    };
    if (
      project?.projectType === "python" ||
      project?.projectType === "pythonGraphics"
    ) {
      if (type === "view") {
        navigate("/playground/python/", {
          state: state,
        });
      } else {
        navigate("/playground/python", {
          state: state,
        });
      }
    } else {
      if (type === "view") {
        navigate("/playground/view", {
          state: state,
        });
      } else {
        navigate("/playground", {
          state: state,
        });
      }
    }
  };

  return (
    <>
      <Typography
        variant="h4"
        gutterBottom
        component="h2"
        className="playground-headings select-project-heading"
      >
        {type === "Your Projects" ? (
          <>
            Your Projects ( {projects.length + "/" + props?.maxProjectLimit} )
          </>
        ) : (
          type === "Community Projects" && "Community Projects"
        )}
      </Typography>
      <Container className="playground-projects-container" maxWidth={false}>
        {type === "Your Projects" ? (
          <ProjectCardsContainer>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginLeft: "1.5vw",
                width: "100%",
              }}
            >
              {props?.projects?.map((project) => {
                return (
                  <CommunityProjectCard
                    key={project?.project_id}
                    project={project}
                    gotoPlayground={gotoPlayground}
                    playgroundProjectIdList={playgroundProjectIdList}
                    isPlayground={true}
                    handleDeleteProjectPopupOpen={
                      props?.handleDeleteProjectPopupOpen
                    }
                  />
                );
              })}
              <Card
                className="project-card user-project-card"
                onClick={() => handleOpen()}
                sx={{
                  backgroundColor: "#DDE9F5",
                }}
              >
                <CardActionArea>
                  <CardMedia
                    alt="project Img"
                    className="project-card-content-container"
                  >
                    <AddIcon className="project-card-add-icon" />
                  </CardMedia>
                  <CardContent className="project-card-content-content">
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="span"
                      className="project-title"
                      sx={{
                        marginTop: "4px",
                      }}
                    >
                      Create Project
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          </ProjectCardsContainer>
        ) : (
          type === "Community Projects" && (
            <ExpandableSectionHandler
              initialExpandedSections={expandedSections}
            >
              {({ expandedSections, handleExpandSection }) => (
                <ExpandableProjectGroup
                  projectsSectionWise={projects}
                  projectsExpandedSection={expandedSections}
                  handleExpandSection={handleExpandSection}
                  gotoPlayground={gotoPlayground}
                  playgroundProjectIdList={playgroundProjectIdList}
                  noProjectsPublished={
                    !Object.values(projects).some(
                      (section) => section.length > 0
                    )
                  }
                />
              )}
            </ExpandableSectionHandler>
          )
        )}
      </Container>
      {open && (
        <CreateProjectModal
          projects={props?.createProjectSet}
          canCreateProject={props?.canCreateProject}
          handleClose={handleClose}
          section={props?.section}
          open={open}
          playgroundProjectIdList={playgroundProjectIdList}
          pythonProjectEnabled={props?.pythonProjectEnabled}
          pythonGraphicsEnabled={props?.pythonGraphicsEnabled}
          helpInstructionIds={helpInstructionIds}
        />
      )}
    </>
  );
}
