import React, { useEffect, useRef, useState } from "react";
import {
  CardActionArea,
  Container,
  Typography,
  CardMedia,
  CardContent,
  Card,
  FormControl,
  OutlinedInput,
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  IconButton,
  styled,
  DialogContent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SportsEsportsOutlinedIcon from "@mui/icons-material/SportsEsportsOutlined";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import PythonButton from "../ModuleComponentsButtons/PythonButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    fontFamily: "rajdhani-medium",
    border: "3px solid #25A596",
    borderRadius: "3vw",
    maxWidth: "60vw",
    width: "60vw",
    height: "fit-content",
    padding: "1rem",
    position: "absolute",
    top: 0,
    backgroundColor: "rgba(255,255,255,0.85)",
  },
  "& #customized-dialog-title": {
    display: "flex",
    justifyContent: "center",
    fontFamily: "rajdhani-medium",
    fontSize: "5vw",
    margin: 0,
    padding: 0,
    lineHeight: "5vw",
    fontWeight: "normal",
  },
  "& .MuiPaper-root .MuiTypography-gutterBottom": {
    textAlign: "center",
    fontFamily: "rajdhani-medium",
    fontSize: "2vw",
    marginTop: 0,
  },
  "& .MuiDialogActions-root": {
    justifyContent: "center",
    padding: 0,
    margin: 0,
  },
  "& .MuiDialogActions-root Button": {
    fontFamily: "rajdhani-medium",
    color: "black",
    border: "none",
    borderRadius: "10px",
    fontSize: "1.3rem",
    padding: "0 3rem !important",
    transition: "0.5s",
    textTransform: "none",
    margin: 0,
    width: "5vw",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      transform: "scale(1.05)",
      transition: "0.5s",
    },
  },
  "& #create-project-action-buttons": {
    display: "flex",
  },
  "& #denied-create-project": {
    backgroundColor: "#FF9D00 !important",
    marginRight: "1vw",
  },
  "& #confirm-create-project": {
    backgroundColor: "#aecb2a !important",
  },
  "& #MuiDialogContent-root": {
    padding: "0 !important",
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#343a40",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CreateProjectModal(props) {
  const {
    playgroundProjectIdList,
    pythonProjectEnabled,
    pythonGraphicsEnabled,
    helpInstructionIds,
  } = props;
  const [projectTitle, setProjectTitle] = useState("");
  const navigate = useNavigate();
  const [selected, setSelected] = useState(-1);
  const [createClicked, setCreateClicked] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (
      (selected >= 0 ||
        selected === "python" ||
        selected === "pythonGraphics") &&
      inputRef.current
    ) {
      inputRef.current.querySelector("input").focus();
    }
  }, [selected]);
  const gotoPlayground = (project, type) => {
    if (selected == "python" || selected == "pythonGraphics") {
      const payload = {
        project_data: {
          projectType: selected,
          title: projectTitle,
          published: false,
          status: "Inactive",
        },
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      };
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/upload-playground-project`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          navigate("/playground/python", {
            state: {
              projectDetails: data,
              type: type,
              section: props.section,
              helpInstructionIds: helpInstructionIds,
            },
          });
        });
    } else {
      const payload = {
        project_data: {
          projectType: "game",
          title: projectTitle,
          published: false,
          status: "Inactive",
          strapi_game_set_id: project?.id,
        },
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      };
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/upload-playground-project`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          navigate("/playground", {
            state: {
              projectDetails: data,
              type: type,
              section: props.section,
            },
          });
        });
    }
  };

  const handleSelectProject = (index) => {
    if (selected === index) {
      setSelected(-1);
    } else {
      setSelected(index);
    }
  };

  const handleInputChange = (e) => {
    if (e.target.value.trim().length === 0) {
      setProjectTitle(e.target.value.trim());
    } else if (e.target.value.trim().length < 20)
      setProjectTitle(e.target.value);
  };

  const handleCreateProject = () => {
    if (!createClicked) setCreateClicked(true);
    if (selected >= 0 && projectTitle) {
      gotoPlayground(props?.projects[selected], "create");
    } else if (
      (selected === "python" || selected === "pythonGraphics") &&
      projectTitle
    ) {
      gotoPlayground("python", "create");
    } else if (
      selected >= 0 ||
      selected === "python" ||
      selected === "pythonGraphics"
    ) {
      inputRef.current.querySelector("input").focus();
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={props?.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props?.open}
        className={props.canCreateProject ? "create-project-modal" : ""}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props?.handleClose}
        ></BootstrapDialogTitle>
        <DialogContent className="create-project-dialog">
          {!props.canCreateProject ? (
            <div className="reach-limit-message-container">
              <p>Congratulations!!</p>
              <p>You have completed all projects.</p>
              <p>To create a new project delete one of your projects.</p>
            </div>
          ) : (
            <>
              <div>
                <div className="select-project-header-container">
                  <Typography
                    variant="h4"
                    gutterBottom
                    component="p"
                    className="create-project-headings select-project-heading"
                  >
                    Select Project :
                  </Typography>
                  {createClicked && selected < 0 && (
                    <span className="create-project-error-message">
                      Please select a project from below.
                    </span>
                  )}
                </div>
                <Container className="playground-create-projects-container">
                  {props.projects?.map((project, index) => (
                    <Card
                      key={project.id}
                      className={`project-card create-project-card ${
                        selected === index ? "selected-project-card" : ""
                      }`}
                      onClick={() => handleSelectProject(index)}
                      sx={{
                        backgroundColor: "#DDE9F5",
                      }}
                    >
                      <CardActionArea>
                        <CardMedia
                          alt="project Img"
                          className="project-card-content-container"
                        >
                          <AddIcon className="project-card-add-icon" />
                        </CardMedia>
                        <CardContent className="project-card-content-content">
                          <div className="project-card-title-content">
                            <SportsEsportsOutlinedIcon />
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="span"
                              className="project-title"
                            >
                              {project["Game Set Name"]}
                            </Typography>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  ))}
                  {pythonProjectEnabled && (
                    <Card
                      className={`project-card create-project-card ${
                        selected === "python" ? "selected-project-card" : ""
                      }`}
                      onClick={() => handleSelectProject("python")}
                      sx={{
                        backgroundColor: "#DDE9F5",
                      }}
                    >
                      <CardActionArea>
                        <CardMedia
                          alt="project Img"
                          className="project-card-content-container"
                        >
                          <AddIcon className="project-card-add-icon" />
                        </CardMedia>
                        <CardContent className="project-card-content-content">
                          <div className="project-card-title-content">
                            <PythonButton status={"transparent"} icon={true} />
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="span"
                              className="project-title"
                            >
                              Python
                            </Typography>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  )}
                  {pythonGraphicsEnabled && (
                    <Card
                      className={`project-card create-project-card ${
                        selected === "pythonGraphics"
                          ? "selected-project-card"
                          : ""
                      }`}
                      onClick={() => handleSelectProject("pythonGraphics")}
                      sx={{
                        backgroundColor: "#DDE9F5",
                      }}
                    >
                      <CardActionArea>
                        <CardMedia
                          alt="project Img"
                          className="project-card-content-container"
                        >
                          <AddIcon className="project-card-add-icon" />
                        </CardMedia>
                        <CardContent className="project-card-content-content">
                          <div className="project-card-title-content">
                            <PythonButton status={"transparent"} icon={true} />
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="span"
                              className="project-title"
                            >
                              Python Graphics
                            </Typography>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  )}
                </Container>
              </div>
              <div className="d-flex">
                <Typography
                  variant="h4"
                  component="p"
                  className="create-project-headings project-name-heading"
                  sx={{ color: selected >= 0 ? "inherit" : "#bcbbbb" }}
                >
                  Name Your Project :
                </Typography>
                <div className="project-name-input-container">
                  <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                    <OutlinedInput
                      id="project-title-input"
                      value={projectTitle}
                      onChange={(e) => handleInputChange(e)}
                      className={
                        selected >= 0 ||
                        selected === "python" ||
                        selected === "pythonGraphics"
                          ? ""
                          : "project-not-selected"
                      }
                      disabled={selected < 0}
                      ref={inputRef}
                    />
                  </FormControl>
                  {createClicked && !projectTitle && (
                    <p className="create-project-error-message">
                      Please Name Your Project.
                    </p>
                  )}
                </div>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions className="create-project-action-container">
          <div id="create-project-action-buttons">
            <Button onClick={props?.handleClose} id="denied-create-project">
              {props.canCreateProject ? "Cancel" : "Ok"}
            </Button>
            {props.canCreateProject && (
              <Button
                onClick={() => handleCreateProject()}
                id="confirm-create-project"
              >
                Create
              </Button>
            )}
          </div>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
